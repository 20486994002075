import { Typography ,Button, Box} from "@mui/material"
import UserAuth from "./UserAuth"


function SignOutMessage() {
  return (
    <>
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
        <Typography variant="h5">
            Please Sign In to get started with Measurement Analysis.
        </Typography>
        <UserAuth/>
    </Box>
    </>
  )
}

export default SignOutMessage