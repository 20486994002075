import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextField from "@mui/material/TextField";
import { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import { Box, display } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Snackbar from "@mui/material/Snackbar";

interface childProps {
  serverUrl: string;
  triggerFiles: boolean;
  setTriggerFiles: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthenticated: boolean;
}

interface AlertType {
  type: "info" | "success" | "warning" | "error";
}

const UserUploadDialog: React.FC<childProps> = ({
  serverUrl,
  triggerFiles,
  setTriggerFiles,
  isAuthenticated,
}) => {
  const [open, setOpen] = useState(false);
  const [sasUrl, setSasUrl] = useState<String>("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState<String>("");
  const [severity, setSeverity] = useState<AlertType>({ type: "info" });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const input_ref = useRef<HTMLInputElement>(null);
  const [loader, setLoader] = useState(false);

  const handleSasUrl = (sas_url: String) => {
    setSasUrl(sas_url);
  };

  const handleResponseStatus = async (response: any) => {
    if (response.status == 200) {
      const data = await response.json();
      setShowSnackbar(true);
      setSeverity({ type: "success" });
      setMessage(data.message);
    }

    if (response.status == 400) {
      const data = await response.json();
      setShowSnackbar(true);
      setSeverity({ type: "warning" });
      setMessage(data.message);
    }

    if (response.status == 404) {
      setShowSnackbar(true);
      setSeverity({ type: "error" });
      setMessage("A Network error has occured please try again.");
    }

    if (response.status == 500) {
      setShowSnackbar(true);
      setSeverity({ type: "error" });
      setMessage("A Server error has occured please try again later.");
    }
  };

  const submitSasUrl = async () => {
    handleClose();

    try {
      setLoader(true);
      const response = await fetch(`${serverUrl}/upload-azure-sas`, {
        method: "POST",
        body: JSON.stringify({ sasUrl }),
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });

      await handleResponseStatus(response);

      setLoader(false);
      setSasUrl("");
      setTriggerFiles(!triggerFiles);
    } catch (error) {
      setLoader(false);
      setSasUrl("");

      setShowSnackbar(true);
      setSeverity({ type: "error" });
      setMessage(`An error occured: ${error} please try again.`);
    }
  };

  const handleclick = () => {
    input_ref.current?.click();
  };

  const handleFile = async (file_data: any) => {
    handleClose();
    const formdata = new FormData();
    formdata.append("user_file", file_data[0]);

    try {
      setLoader(true);
      const response = await fetch(`${serverUrl}/upload`, {
        method: "POST",
        body: formdata,
      });

      await handleResponseStatus(response);

      setLoader(false);
      setTriggerFiles(!triggerFiles);
    } catch (error) {
      setShowSnackbar(true);
      setSeverity({ type: "error" });
      setMessage(`An error occured: ${error} please try again.`);
    }
  };

  if (loader) {
    return (
      <>
        <Button color="secondary" size="large" variant="contained">
          Uploading...
          <CircularProgress
            color="inherit"
            size="2em"
            sx={{ marginLeft: "1em" }}
          />
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        size="large"
        disabled={!isAuthenticated}
        sx={isAuthenticated ? { display: "block" } : { display: "none" }}
      >
        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
          Upload
          <CloudUploadIcon />
        </Box>
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Your File to Robolyzer Cloud</DialogTitle>

        <DialogContent>
          <DialogContentText>
            To Upload your carot(.bag) or .bytesoup file on to Robolyzer Cloud,
            Select file from your local PC or Share file by giving the Azure SAS
            Token
          </DialogContentText>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ margin: "2em" }}
          >
            <Button
              color="primary"
              size="large"
              variant="contained"
              endIcon={<DriveFolderUploadIcon />}
              onClick={handleclick}
            >
              Upload File From PC
            </Button>
          </Box>

          <Divider>OR</Divider>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="2em"
            sx={{ margin: "1em" }}
          >
            <TextField
              sx={{ ml: 1, flex: 1 }}
              placeholder="Azure SAS URL"
              inputProps={{ "aria-label": "Azure SAS URL" }}
              variant="standard"
              margin="dense"
              value={sasUrl}
              onChange={(e) => handleSasUrl(e.target.value)}
              fullWidth
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="proceed"
              onClick={submitSasUrl}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
          <Alert variant="outlined" severity="info">
            <Typography>
              Creating a SAS token in Azure allows limited file access without
              exposing your storage account key. For detailed instructions,
              refer to the official Azure documentation on creating a service
              SAS for a file.
            </Typography>
            <Typography>
              <a
                href="https://docs.microsoft.com/en-us/rest/api/storageservices/create-service-sas#creating-a-service-sas-for-a-file"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "underline" }}
              >
                {" "}
                See Azure documentation
                <OpenInNewIcon sx={{ width: "15px" }} />
              </a>
              .
            </Typography>
          </Alert>
        </DialogContent>
      </Dialog>
      <input
        type="file"
        style={{ display: "none" }}
        ref={input_ref}
        onChange={(e) => handleFile(e.target.files)}
      />

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3500}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={severity.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          <Typography sx={{ color: "white" }}>{message}</Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserUploadDialog;
