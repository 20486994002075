import { createTheme, PaletteMode, ThemeProvider } from "@mui/material";
import "./App.scss";
import Main from "./components/Main/Main";
import variables from "./styles/Variables.scss";
import { useBrowserStorage } from "./utils/useBrowserStorage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10,
    },
  },
});

// TODO scss kinda doesnt work with jest testing out of the box throws errors with the variables
// maybe mock the theme inside the app test
const darkPalette = {
  primary: {
    main: variables.primary,
  },
  secondary: {
    main: variables.secondary,
  },
  tertiary: {
    main: variables.darkThemeTertiary,
  },
  background: {
    default: variables.darkThemeBg,
  },
  customBackground: {
    default: variables.darkGrayColor,
  },
  gridAxis: {
    default: variables.grayColor,
  },
  border: {
    default: variables.grayColor,
  },
  borderDimmed: {
    default: variables.dimmedBorderDark,
  },
  iconBg: {
    default: "#4e33d9",
    paper: "#5948af",
  },
};
const whitePalette = {
  primary: {
    main: variables.lightThemeFont,
  },
  secondary: {
    main: variables.lightThemeFontBlack,
  },
  tertiary: {
    main: variables.lightThemeTertiary,
  },
  background: {
    default: variables.lightThemeBg,
  },
  customBackground: {
    default: variables.grayColor,
  },
  gridAxis: {
    default: "#000000",
  },
  border: {
    default: variables.grayColor,
  },
  borderDimmed: {
    default: variables.dimmedBorderLight,
  },
  iconBg: {
    default: "#787bff",
    paper: "#9a9cff",
  },
};
declare module "@mui/material/styles" {
  interface Palette {
    customBackground: Palette["background"];
    gridAxis: Palette["background"];
    border: Palette["background"];
    borderDimmed: Palette["background"];
    iconBg: Palette["background"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    customBackground?: PaletteOptions["background"];
    gridAxis?: PaletteOptions["background"];
    border?: PaletteOptions["background"];
    borderDimmed?: PaletteOptions["background"];
    iconBg?: PaletteOptions["background"];
  }
}

const App = () => {
  const getting = window.matchMedia("(prefers-color-scheme: dark)");
  const themeValue: string = getting.matches ? "dark" : "light";
  // local storage themevalue
  const [mode, setMode] = useBrowserStorage("local", "themeValue", {
    theme: themeValue,
  });

  const theme = createTheme({
    palette: {
      mode: mode.theme as PaletteMode,
      ...(mode.theme === "dark" ? { ...darkPalette } : { ...whitePalette }),
    },
    typography: {
      fontFamily: variables.fontFamily,
      fontSize: 12.25,
    },
    components: {
      // Name of the component
      MuiAccordionDetails: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            padding: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: variables.fontMin,
            minWidth: 0,
            padding: "8px",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "unset",
          },
        },
      },
    },
  });

  const handleThemeChange = () => {
    const newMode = mode.theme === "light" ? "dark" : "light";
    setMode({ theme: newMode });
  };

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <div className={`w-100 h-100 ${mode.theme}`}>
          <Main handleThemeChange={handleThemeChange}></Main>
        </div>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
