import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from '@mui/icons-material/Logout';
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import React, { useState } from "react";


const UserAuth:React.FC = () => {
  const { instance,accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [isLoading,setLoader] = useState(false)

  const handleLogin = () => {

    setLoader(true)
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
    console.log(accounts[0])
  };

  const handleLogout = () => {
    // setting the loader of signing to false
    setLoader(false)
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  if (isAuthenticated) {
    return (
      <div>
        <Button
          variant="contained"
          onClick={() => handleLogout()}
          sx={{ fontSize: "14px",color:'#ffffff',bgcolor: '#B23B3B', '&:hover': { bgcolor: '#CE5E5E' }}}
        >
          <Box display="flex" gap={1}>
            Sign Out
            { isLoading? <CircularProgress color="inherit"
            size="1.5em"/> : <LogoutIcon />}
          </Box>
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => handleLogin()}
        sx={{ fontSize: "14px",bgcolor: '#BBDEFB', '&:hover': { bgcolor: '#E3F2FD' } }}
      >
        <Box display="flex" gap={1}>
          Sign In
          { isLoading? <CircularProgress color="inherit"
            size="1.5em"/> : <LoginIcon />}
        </Box>
      </Button>
    </div>
  );
}

export default UserAuth;
