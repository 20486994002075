/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
interface MapObject {
  dataType: string;
  value: [any, any][];
}

function replacer(key: string, value: object) {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: [...value],
    };
  } else {
    return value;
  }
}

function reviver(key: string, value: MapObject) {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    }
  }
  return value;
}

// Hook
export function useBrowserStorage<T>(
  storageType: string,
  key: string,
  initialValue?: T
): [T, (value: T | ((prevState: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item =
        storageType === "local"
          ? window.localStorage.getItem(key)
          : window.sessionStorage.getItem(key);
      return item ? JSON.parse(item, reviver) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T | ((prevState: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        storageType === "local"
          ? window.localStorage.setItem(
              key,
              JSON.stringify(valueToStore, replacer)
            )
          : window.sessionStorage.setItem(
              key,
              JSON.stringify(valueToStore, replacer)
            );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStorageChange = (e: StorageEvent) => {
    if (e.key === key) {
      if (e.newValue) {
        setValue(JSON.parse(e.newValue));
      }
    }
    // swap between e.key maybe to handle logic for specific local storage vars like maps
  };

  useEffect(() => {
    if (window.opener) {
      // For some reason when chaning theme value removing and adding happens 2 times and for filterconfig only once
      window.addEventListener("storage", handleStorageChange);
    }
    return () => {
      if (window.opener) {
        window.removeEventListener("storage", handleStorageChange);
      }
    };
  });

  return [storedValue, setValue];
}
