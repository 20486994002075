import { Box, Drawer, IconButton } from "@mui/material";
import { Allotment } from "allotment";
import { useState } from "react";

import { useIsAuthenticated } from "@azure/msal-react";
import { useBrowserStorage } from "../../utils/useBrowserStorage";
import "./Main.scss";
import Home from "../Home/Home";

interface MainProps {
  handleThemeChange: () => void;
}

export interface Layout {
  sidebar: boolean;
  bottomBar: boolean;
  bottomLeft: boolean;
  bottomRight: boolean;
  mainLeft: boolean;
  mainRight: boolean;
}
const defaultLayout: Layout = {
  sidebar: true,
  bottomBar: true,
  bottomLeft: true,
  bottomRight: true,
  mainLeft: true,
  mainRight: false,
};

const Main = (props: MainProps) => {
  const [layout, setLayout] = useBrowserStorage(
    "local",
    "layout",
    defaultLayout
  );
  return (
    <Box
      sx={{
        bgcolor: "background.default",
        color: "text.primary",
        display: "flex",
      }}
      className={`container h-100 ${!layout.sidebar ? "sidebar-visible" : ""}`}
    >
      <Home />
    </Box>
  );
};

export default Main;
